.mobile-nav__button {
  background: url('@/static/images/icons/default/icon-open-tablet-nav.svg') no-repeat 20px 25px;
  display: none;
  height: 60px;
  left: 10px;
  position: fixed;
  top: 10px;
  width: 60px;
  z-index: 12;
  cursor: pointer;

  &.open-mobile-nav {
    background: url('@/static/images/icons/default/icon-close-tablet-nav.svg') no-repeat 25px 25px;
  }

  @media #{$tablet} {
    display: block;
  }
}

.mobile-nav {
  display: none;
  left: -100%;
  position: fixed;
  right: 0;
  top: 75px;
  transition: left ease .3s;
  width: 100%;
  height: calc(100vh - 75px);
  z-index: 11;

  @media #{$tablet} {
    display: block;
  }

  &.open-mobile-nav {
    left: 0;
  }

  &:before {
    position: absolute;
    top: -6px;
    left: 0;
    z-index: 1;
    width: 100%;
    height: 5px;
    content: '';
    background-color: transparent;
    box-shadow: 0 5px 5px rgba(0,0,0,.15);
  }

  &__main {
    height: inherit;
    background-color: $color-white;
    overflow-y: auto;
    overflow-x: hidden;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .menu__apps {
      padding: 30px 32px 0;
      margin-bottom: 0;

      &--item {
        max-width: 280px;
        margin-right: 8px;

        @media #{$extra-small} {
          margin-right: 0;
        }

        + .menu__apps--item {
          margin-left: 8px;
          margin-right: 0;

          @media #{$extra-small} {
            margin-left: 0;
            margin-top: 10px;
          }
        }
      }
    }

    .menu__apps-wrapper {
      flex-direction: row;
      justify-content: center;

      @media #{$extra-small} {
        flex-direction: column;
        align-items: center;
      }
    }

    .menu__apps-title:not(:first-of-type) {
      margin-top: 20px;
      margin-bottom: 0;
    }

    .contact-block__information__wrapper-btn {
      padding-top: 5px;

      .btn-free-online-call {
        @include btn-with-icon($color-title, 10%);
        color: $color-white;

        .btn-with-icon-image {
          background: $color-title;

          svg {
            path {
              fill: $color-white;
            }
          }
        }
      }

      .btn {
        margin-bottom: 15px;
      }
    }

    .static-header__phones {
      display: block;
      margin: 0;
      padding: 0;

      .static-header__phones-caret {
        display: none;
      }
    }
  }

  &__list-item {
    &__sub {
      display: none;
      transition: all .5s linear;
      border-left: 2px solid $color-blue;
      padding: 32px 0;

      .static-header__nav-drop-down__column {
        padding: 0 32px;
      }

      .static-header__nav-drop-down__item {
        @media #{$extra-small} {
          width: 100%;
        }
      }

      &.tw-pt-0 {
        padding-top: 0;
        padding-bottom: 0;
      }

      &.tw-pb-0 {
        padding-bottom: 0;
      }

      ul {

        li {
          display: flex;
          align-items: flex-start;
          line-height: 21px;
          justify-content: flex-start;
          font-weight: bold;
          font-size: 14px;
          letter-spacing: .2px;
          color: $color-title;
          transition: all .5s ease;
          position: relative;

          a {
            color: $color-title;
            line-height: 1.5;
            width: 100%;
          }

          &:hover {
            a {
              color: $color-blue;
            }
          }

          &.mobile-nav__list-item__sub-first-list__link {
            color: $color-blue;

            a {
              color: $color-blue;
            }

            &:after {
              display: none;
            }
          }
        }
      }

      .static-header__nav-sections__item-list {
        width: calc(100% - 32px);
        display: grid;
        grid-template-columns: 50% 50%;
        grid-template-rows: auto;
        grid-column-gap: 32px;
        column-gap: 34px;
        padding: 0 32px;

        @media #{$extra-small} {
          width: 100%;
          display: grid;
          grid-template-columns: 100%;
          grid-column-gap: 0;
          column-gap: 0;
          padding: 32px;
        }

        .static-header__nav-drop-down__item {
          width: 100%;
        }

        .static-header__nav-drop-down__card {
          margin-top: 0;
          margin-bottom: 35px;
        }
      }
    }

    &__title,
    &__sub_first-title {
      display: flex;
      align-items: center;
      line-height: 21px;
      justify-content: flex-start;
      font-weight: bold;
      font-size: 14px;
      letter-spacing: .2px;
      color: $color-title;
      transition: all .5s ease;
      position: relative;
      padding: 16px 48px 16px 32px;

      &:after {
        border-color: #ccc;
        border-style: solid;
        border-width: 2px 2px 0 0;
        box-sizing: inherit;
        content: '';
        height: 10px;
        margin-top: -5px;
        position: absolute;
        right: 32px;
        top: 50%;
        transform: rotate(135deg);
        vertical-align: top;
        width: 10px;
        transition: all .2s ease;
      }

      &:before {
        content: '';
        position: absolute;
        background: rgba(0, 0, 0, .2);
        width: calc(100% - 64px);
        height: 1px;
        left: 32px;
        top: 0;
      }
    }

    &__sub_first-title {
      &.with-sub-title {
        &::after {
          transform: rotate(135deg);
        }
      }
    }

    &.active {
      > .mobile-nav__list-item__sub {
        display: block;
        transition: all .5s linear;

        > .mobile-nav__list-item {
          .mobile-nav__list-item__title {
            border-left: 0;
            background-color: $color-white;
            color: $color-title;

            &:after {
              border-color: #ccc;
            }
          }

          &.active {
            .mobile-nav__list-item__title {
              background-color: $color-sky-blue;
              color: $color-blue;

              &:after {
                border-color: $color-blue;
                transform: rotate(-45deg);
              }
            }
          }
        }

        .mobile-nav__list-item__sub {
          border-left: 0;

          .static-header__nav-drop-down__card-label {
            margin-left: 32px;
          }
        }
      }

      > .mobile-nav__list-item__title {
        background-color: #FEEFE7;
        border-left: 2px solid $color-orange;

        > .mobile-nav__list-item__title {
          background-color: $color-sky-blue;
          color: $color-blue;
        }

        &:before {
          display: none;
        }
      }
    }

    &:hover,
    &.active {
      > .mobile-nav__list-item__title {
        color: $color-orange;
        cursor: pointer;
        &:after {
          border-color: $color-orange;
        }
      }

      > .mobile-nav__list-item__sub {
        .mobile-nav__list-item__title {
          background-color: $color-sky-blue;
          color: $color-blue;

          &:after {
            border-color: $color-blue;
            transform: rotate(135deg);
          }
        }
      }
    }

    &__sub-first-list {
      padding: 30px 0 10px;
      background: rgba(37, 134, 234, .1);
    }

    &:first-of-type {
      .mobile-nav__list-item__title {
        &:before {
          display: none;
        }
      }
    }
  }

  &__list-item__sub-first {
    &-list {
      display: none;
      transition: all .5s linear;
    }

    &.active {
      .mobile-nav__list-item__sub-first-list {
        display: block;
        transition: all .5s linear;
      }
    }

    &.active,
    &:hover {
      .mobile-nav__list-item__sub_first-title {
        color: $color-orange;

        &::after {
          border-color: $color-orange;
        }
      }
    }

    &:last-of-type {
      .mobile-nav__list-item__sub-first-list {
        padding-bottom: 0;
      }
    }
  }

  &__tax-guides-info,
  &__pricing-plans,
  &__articles {
    background-color: $color-sky-blue;

    .static-header__country-guide {
      padding: 45px 32px;
      margin-bottom: 0;
    }

    .static-header__nav-drop-down__item {
      width: 33%;

      @media #{$extra-small} {
        width: 100%;
      }
    }

    .static-header__nav-drop-down__column {
      align-items: flex-start;
      padding: 38px 32px;
    }
  }

  &__pricing-plans,
  &__articles {
    padding: 32px 0;

    .static-header__nav__title,
    .static-header__nav__footer-link {
      margin: 0 32px;
    }

    .static-header__nav__footer-link {
      display: block;
      margin-right: 17px;
    }

    .static-header__nav-drop-down__card {
      background-color: transparent;
    }
  }

  &__articles {
    .static-header__articles-list {
      padding: 32px;
      display: flex;

      @media #{$extra-small} {
        flex-direction: column;
      }

      .static-header__articles-list__item {
        height: auto;
        margin: 0 15px 0 0;
        width: calc(33% - 8px);
        flex-direction: column;
        justify-content: space-between;

        @media #{$extra-small} {
          width: 100%;
          margin: 0 0 15px;

          &:last-of-type {
            margin-bottom: 0;
          }
        }

        .static-header__articles-list__item-content {
          padding: 15px 20px;
          width: 100%;
        }

        .static-header__articles-list__item-photo {
          width: 100%;
          max-height: 160px;
          height: 100%;
        }
      }
    }
  }

  &__footer {
    display: flex;
    flex-direction: row;
    padding: 12px 0;
    width: 100%;
    margin-top: 15px;

    @media #{$mobile} {
      padding-bottom: 100px;
    }
  }
}
