$static-header-drop-down-box-shadow: 0 0 8px rgba(0, 0, 0, 0.25);
$static-header-phones-border: #d4d4d4;
$static-header-search-bg: rgba(30, 42, 66, 0.85);
$small-right-side: 325px;
$largest-right-side: 574px;

@keyframes flying-arrow {
  0% {
    transform: translateX(0);
  }
  50% {
    transform: translateX(8px);
  }
  to {
    transform: translateX(0);
  }
}

.static-header {
  align-items: center;
  background: $color-white;
  border-bottom: 1px solid #eff0f4;
  box-sizing: border-box;
  display: flex;
  height: 75px;
  justify-content: space-between;
  padding: 0 30px;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 1040;

  @media #{$md} {
    padding: 0 10px;
  }

  @media #{$tablet} {
    padding-left: 85px;
  }

  @media #{$mobile} {
    justify-content: flex-end;
  }

  .btn {
    align-items: center;
    display: flex;
    font-size: 14px;
    letter-spacing: 1.4px;
    line-height: 1;
    min-height: 46px;
    padding: 0 15px;
    border-radius: 4px;

    @media #{$ie} {
      padding-top: 14px;
    }
  }

  img {
    display: block;
    height: auto;
    max-width: 100%;
  }

  &__right-part {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    background-color: $color-sky-blue;
    padding: 20px 45px;
    height: 100%;
    overflow: visible;
    max-width: $largest-right-side;
    width: 100%;

    @media #{$lg} {
      max-width: 500px;
    }

    @media #{$md} {
      max-width: 460px;
      padding: 20px;
    }

    &.about-us-right-side {
      max-width: $largest-right-side;

      @media #{$lg} {
        max-width: 500px;
      }

      @media #{$md} {
        max-width: 325px;
      }
    }

    &.services-right-side,
    &.tax-guide-right-side {
      max-width: $small-right-side;

      .static-header__nav-drop-down__card {
        max-width: 225px;
        background: $color-sky-blue;
      }

      .static-header__nav-drop-down__card {
        &:hover {
          &:before {
            display: none;
          }
        }
      }
    }

    .static-header__nav__footer-link {
      margin-top: 40px;
      max-width: 150px;
    }
  }

  // Articles

  &__articles-list {
    overflow: visible;
    height: 100%;

    &__item {
      position: relative;
      width: 100%;
      height: 150px;
      background: $color-white;
      display: flex;
      margin: 0 0 8px;
    }

    &__item-photo {
      position: relative;
      height: 100%;
      width: 140px;
      align-items: center;
      display: flex;
      justify-content: center;
      overflow: hidden;

      img {
        width: 100%;
        height: 100%;
        line-height: 0;
        object-fit: cover;
      }
    }

    &__item-content {
      width: calc(100% - 140px);
      align-items: flex-start;
      display: flex;
      flex-wrap: wrap;
      flex-direction: column;
      padding: 15px 20px 20px 40px;

      @media #{$lg-xl} {
        padding: 12px 15px 15px 20px;
      }
    }

    &__item-date {
      color: $color-grey;
      font-size: 10px;
      font-weight: 700;
      line-height: 19px;
    }

    &__item-title {
      font-style: normal;
      font-weight: bold;
      font-size: 14px;
      line-height: 18px;
      color: $color-title;
      overflow: hidden;
      margin: 0;
      max-height: 75px;
      padding-bottom: 10px;

      @media #{$ie} {
        max-width: 130px;
      }
    }

    &__item-link {
      font-weight: bold;
      font-size: 14px;
      line-height: 16px;
      width: 100%;
      display: block;
      color: $color-blue;
    }
  }

  // Navigation
  &__nav {
    align-items: center;
    display: flex;
    height: 100%;

    @media #{$tablet} {
      display: none;
    }

    &__footer {
      z-index: 10;
      background: $color-white;
      width: 100vw;
      border-top: 1px solid #eff0f4;
      height: 40px;
      display: flex;
      align-items: center;
      justify-content: center;
      position: absolute;
      top: calc(100% - 40px);
      left: 0;
    }

    &__footer-inner {
      max-width: 1400px;
      width: 100%;
      padding: 0 50px;

      &--absolute {
        position: absolute;
        left: auto;
        right: auto;

        @media #{$ie} {
          position: relative;
        }
      }
    }

    &__footer-link,
    &__solo-link {
      font-weight: bold;
      font-size: 14px;
      display: inline-block;
      height: 22px;
      line-height: 22px;
      color: $color-blue;
      transition: all 0.4ms ease;
      background-image: url("@/static/images/icons/default/icon-arrow.svg");
      background-size: 8px 14px;
      padding-right: 40px;
      background-position: right 15px center;
      background-repeat: no-repeat;
      margin-right: 20px;
      border-right: 1px solid #eff0f4;

      @media #{$mobile} {
        background-position: right 15px center;
        margin-right: 15px;
        padding-right: 35px;
      }

      &:hover {
        color: $color-title;
        background-image: url("@/static/images/icons/default/icon-arrow-dark.svg");
      }

      &:last-of-type {
        margin-right: 0;
        border-right: 0;
      }
    }

    &__solo-link {
      display: inline-block;
      max-width: 150px;
      margin-top: 30px;
    }

    &__section-list {
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      justify-content: flex-start;
      align-items: flex-start;
      list-style: none;
      min-width: 700px;
      opacity: 0;
      transition: opacity 0.45s cubic-bezier(0, 0, 0.16, 0.96), transform 0.4s cubic-bezier(0, 0, 0.16, 0.96);
      transition-delay: 200ms;
      transform: translateX(100%);
      will-change: transform;
      position: relative;
      width: 100%;
      margin: 0;
      padding: 0;
      height: 100%;
      overflow: auto;
      -ms-overflow-style: none; /* hide scrollbar for IE */
      scrollbar-width: none; /* hide scrollbar for FF */

      /* hide scroll for Chrome, Safari and Opera */
      &::-webkit-scrollbar {
        display: none;
      }

      @media #{$min-width-xl} {
        justify-content: center;
        max-width: 1400px;
      }
    }

    &__title {
      font-style: normal;
      font-weight: bold;
      font-size: 14px;
      line-height: 18px;
      letter-spacing: 0.2px;
      color: $color-blue;
      padding-bottom: 20px;
      border-bottom: 1px solid #eff0f4;
      width: 100%;
      margin-bottom: 40px;
    }

    &__title {
      border-bottom: 1px solid #b6dafd;
    }
  }

  &__nav-drop-down {
    display: flex;
    left: 50%;
    flex-direction: row;
    flex-wrap: nowrap;
    position: fixed;
    justify-content: center;
    text-align: left;
    top: 75px;
    width: 100%;
    z-index: 10;
    height: 0;
    opacity: 0;
    transform: translateX(-50%);
    background-color: $color-white;
    box-shadow: $static-header-drop-down-box-shadow;
    padding-bottom: 40px;

    &:before {
      position: absolute;
      top: -6px;
      left: 0;
      z-index: 1;
      width: 100%;
      height: 5px;
      content: "";
      background-color: transparent;
      box-shadow: 0 5px 5px rgba(0, 0, 0, 0.15);
    }

    &:after {
      position: absolute;
      content: "";
      right: 0;
      top: 0;
      width: 50%;
      height: 100%;
      background: $color-sky-blue;
      z-index: -1;

      @media #{$xl} {
        max-width: $small-right-side;
      }

      @media #{$lg-xl} {
        max-width: $small-right-side;
      }

      @media (max-width: 1100px) {
        max-width: 280px;
      }
    }

    &--about-us {
      &:after {
        @media #{$xl} {
          max-width: $largest-right-side;
        }

        @media #{$lg} {
          max-width: 500px;
        }

        @media #{$md} {
          max-width: 325px;
        }
      }
    }

    &__wrapper {
      opacity: 0;
      box-shadow: 0 50px 60px 0 rgba(36, 50, 66, 0.15);
      position: fixed;
      background-color: $color-light-grey;
      will-change: transform;
      width: 100%;
      left: 0;
      top: 75px;
      z-index: 5;
      transition: transform 300ms cubic-bezier(1, 0.01, 1, 0.99) 0s, opacity 450ms cubic-bezier(1, 0.01, 1, 0.99) 0s;
    }

    &__section {
      padding-bottom: 20px;
      margin: 0 35px 0 0;

      @media #{$lg-xl} {
        margin: 0 15px 0 0;
      }

      @media #{$tablet} {
        margin: 0;
      }

      &--about-us {
        .static-header__nav-drop-down__card {
          @media #{$lg-xl} {
            max-width: 220px;
          }
          @media #{$md} {
            max-width: 200px;
          }
        }
      }
    }

    &__column {
      list-style: none;
      margin: 0;
      padding: 0;

      @media #{$tablet} {
        display: flex;
        flex-wrap: wrap;
        padding: 32px;
      }
    }

    &__item {
      margin: 0;
      padding: 0;
      position: relative;

      &:last-of-type {
        .static-header__nav-drop-down__card {
          margin-bottom: 0;
        }
      }

      &--last {
        &:hover {
          .static-header__nav-drop-down__card-arrow {
            animation: flying-arrow 1s infinite ease-in-out;
          }
        }
      }

      @media #{$tablet} {
        width: 50%;
      }
    }

    &__card {
      width: 100%;
      max-width: 194px;
      position: relative;
      margin-bottom: 40px;
      background: $color-white;
      display: flex;
      z-index: 1;

      &:before {
        content: "";
        position: absolute;
        border-radius: 3px;
        background: $color-sky-blue;
        z-index: -1;
        opacity: 0;
        transform: scale(1.1);
        width: calc(100% + 32px);
        height: calc(100% + 32px);
        top: -16px;
        left: -16px;
        transition: opacity 80ms linear, transform 80ms ease-in;

        @media #{$lg-xl} {
          width: calc(100% + 16px);
          height: calc(100% + 24px);
          top: -12px;
          left: -8px;
        }

        @media #{$tablet} {
          display: none;
        }
      }

      &:hover {
        &:before {
          opacity: 1;
          transform: scale(1);
          transition: opacity 0.2s linear, transform 0.2s ease-out;
        }
      }

      @media #{$lg-xl} {
        max-width: 170px;
      }

      @media #{$tablet} {
        max-width: calc(100% - 32px);
        margin-bottom: 35px;
        background: transparent;
      }
    }

    &__card-img {
      margin: 0 10px 0 0;
      width: 20px;
      min-width: 20px;
    }

    &__card-description {
      width: inherit;

      b {
        font-weight: bold;
        font-size: 14px;
        color: $color-title;
        display: block;
        margin-bottom: 10px;
        line-height: 1.5;
        white-space: nowrap;
        overflow: hidden;
        width: inherit;
        max-width: 100%;
        text-overflow: ellipsis;
      }

      p {
        font-weight: normal;
        font-size: 12px;
        line-height: 19px;
        color: $color-grey;
        margin: 0;
      }
    }

    &__card-label {
      font-weight: bold;
      font-size: 14px;
      display: inline-flex;
      height: 22px;
      line-height: 22px;
      color: $color-blue;
      align-items: center;
    }

    &__card-arrow {
      width: 8px;
      height: 14px;
      background-image: url("@/static/images/icons/default/icon-arrow.svg");
      background-size: 100%;
      background-repeat: no-repeat;
      display: inline-block;
      margin-left: 20px;
    }
  }

  &__small-drop-down {
    display: none;
    padding: 25px 20px 25px 35px;
    width: 200px;
    background: white;
    box-shadow: 0 6px 25px rgba(0, 0, 0, 0.15);
    position: absolute;
    top: 100%;
    border-radius: 4px;
    transform: translate(-37%, -10px);

    nav {
      margin: 0;
      padding: 0;
      list-style: none;
    }

    a {
      color: $color-title;
      display: block;
      margin-bottom: 10px;
      font-weight: bold;
      font-size: 14px;
      line-height: 34px;

      &:hover {
        color: $color-orange;
      }
    }

    &:before {
      position: absolute;
      left: 0;
      right: 0;
      top: -8px;
      content: "";
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 0 8px 8px 8px;
      border-color: transparent transparent white transparent;
      margin: 0 auto;
    }
  }

  &__who-we-help {
    .static-header__nav-link {
      color: $color-orange;
    }

    .static-header__small-drop-down {
      padding-bottom: 0;
      transform: translate(-30%, -10px);

      > nav {
        padding-bottom: 5px;
      }

      > a {
        background-color: $color-sky-blue;
        color: $color-title;
        margin: 0 -20px 0 -35px;
        padding: 16px 0 16px 35px;
        border-bottom-left-radius: 4px;
        border-bottom-right-radius: 4px;

        &:hover {
          color: $color-orange;
        }
      }
    }
  }

  &__nav-item {
    margin: 0 14px;
    padding: 0 10px;
    height: 100%;

    @media #{$xl} {
      margin: 0 10px;
    }

    @media (min-width: 960px) and (max-width: 1024px) {
      margin: 0;
    }

    &:hover {
      text-decoration: none;
      .static-header__nav-link {
        color: $color-orange;
      }
    }

    &.active {
      .static-header__nav-link {
        color: $color-orange;
      }

      .static-header__nav-drop-down {
        z-index: 111;
        opacity: 1;
        height: calc(100vh - 75px);
        max-height: 700px;

        .static-header__nav__section-list {
          opacity: 1;
          transform: translateX(0);
          transition: transform 0.35s cubic-bezier(0, 0, 0.16, 0.96), opacity 0.35s cubic-bezier(0, 0, 0.16, 0.96);
        }
      }

      .static-header__nav-link {
        &:before {
          width: 100%;
          left: 0;
        }
      }

      .static-header__small-drop-down {
        display: block;
        z-index: 1111;
      }
    }
  }

  &__nav-link {
    align-items: center;
    color: $color-title;
    cursor: pointer;
    display: flex;
    font-size: 14px;
    font-weight: 700;
    height: 100%;
    letter-spacing: 0.2px;
    line-height: 1.5;
    white-space: nowrap;
    transition: all ease-in-out 0.2s;
    position: relative;

    &:before {
      content: "";
      position: absolute;
      width: 0;
      height: 3px;
      left: 50%;
      bottom: 0;
      background-color: $color-orange;
      transition: all ease-in-out 0.2s;
    }
  }

  &__nav-wrapper {
    display: flex;
    flex-direction: column;
    background-color: $color-white;
    width: 100%;
    height: auto;
    min-height: 100%;
    padding: 20px 45px;
    overflow: visible;
    position: relative;

    @media #{$md} {
      padding: 20px;
    }
  }

  &__nav-inner {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
    height: 100%;
  }

  &__content {
    align-items: center;
    display: flex;
    height: 100%;
    min-width: 122px;

    @media #{$mobile} {
      min-width: unset;
    }
  }

  .static-header__logo {
    margin: 0;
    text-decoration: none;
    height: 35px;

    img {
      @media #{$mobile} {
        display: block;
        position: absolute;
        left: 66px;
        top: 20px;
      }
    }
  }

  &__nav-sections {
    display: flex;
    position: relative;
    height: 100%;

    &:before {
      content: "";
      position: absolute;
      width: 100%;
      height: 1px;
      left: 0;
      top: 37px;
      background-color: #eff0f4;
    }

    &__item {
      &:last-of-type {
        margin-right: 0;
      }

      &.active,
      &:first-of-type {
        .static-header__nav-sections__item-content {
          display: block;
        }

        .static-header__nav-sections__item-title {
          color: $color-blue;
          cursor: pointer;

          &:before {
            width: 100%;
            left: 0;
          }
        }
      }

      &.active {
        .static-header__nav-sections__item-list {
          display: grid;
        }
      }

      &:first-of-type:not(.active) {
        .static-header__nav-sections__item-title {
          color: $color-title;

          &:before {
            width: 0;
            left: 0;
          }
        }
      }

      &.active:first-of-type {
        .static-header__nav-sections__item-title {
          color: $color-blue;
        }
      }
    }

    &__item-title {
      font-style: normal;
      font-weight: bold;
      font-size: 14px;
      line-height: 18px;
      height: 38px;
      letter-spacing: 0.2px;
      color: $color-title;
      padding-bottom: 20px;
      width: auto;
      margin-right: 35px;
      white-space: nowrap;
      transition: all ease-in-out 0.2s;
      position: relative;

      &:before {
        content: "";
        position: absolute;
        width: 0;
        height: 3px;
        left: 50%;
        bottom: 0;
        background-color: $color-blue;
        transition: all ease-in-out 0.2s;
      }

      &:hover {
        color: $color-blue;
        cursor: pointer;

        &:before {
          width: 100%;
          left: 0;
        }
      }
    }

    &__item-content {
      display: none;
      position: absolute;
      top: 38px;
      left: 0;
      width: 100%;
      height: calc(100% - 30px);
      padding-top: 30px;
      background-color: $color-white;
      z-index: 1;
    }

    &__item-list {
      display: none;
      grid-template-columns: 33% 33% 33%;
      grid-template-rows: auto;
      column-gap: 45px;
      width: calc(100% - 90px);

      .static-header__nav-drop-down__card {
        max-width: unset;
      }

      @media #{$ie} {
        display: block;

        .static-header__nav-drop-down__item {
          display: inline-block;
          float: left;
          margin-right: 30px;
          height: 150px;
        }
      }
    }
  }

  // Guide
  &__country-guide {
    display: block;
    padding: 30px 0 45px;
    margin-bottom: 40px;
    border-bottom: 1px solid #b6dafd;
  }

  &__country-guide-photo {
    display: flex;
    align-items: center;
  }

  &__country-guide-image {
    padding: 6px;
    background-color: $color-white;
    border-radius: 4px;
    margin-right: 15px;
  }

  &__country-guide-title {
    font-weight: bold;
    font-size: 13px;
    line-height: 18px;
    letter-spacing: 0.2px;
    color: $color-title;
    margin: 0;
  }
}

// WhatsApp

.static-header__button-whatsapp {
  cursor: pointer;
  margin: 0 5px 0 0;
  padding: 10px;

  img {
    min-width: 24px;
    min-height: 24px;
  }

  @media #{$mobile} {
    display: none;
  }
}

// Search

.static-header__button-search {
  cursor: pointer;
  margin: 0 20px 0 0;
  padding: 10px;

  img {
    min-width: 24px;
    min-height: 24px;
  }

  @media (min-width: 960px) and (max-width: 1024px) {
    margin-right: 10px;
  }

  @media #{$mobile} {
    margin-right: 50px;
  }
}

// Phones

.static-header__phones {
  align-items: center;
  box-sizing: border-box;
  display: flex;
  min-height: 46px;
  padding: 5px 27px 5px 3px;
  position: relative;

  @media (min-width: 960px) and (max-width: 1024px) {
    padding-right: 0;
  }

  @media #{$mobile} {
    display: none;
  }

  &.without-tooltip {
    padding-right: 3px;
  }
}

.static-header__phone-component {
  display: block;
}

.static-header__phones-caret {
  bottom: 0;
  cursor: pointer;
  position: absolute;
  right: 0;
  top: 0;
  width: 27px;

  &::before,
  &::after {
    background: $color-title;
    border-radius: 1px;
    content: "";
    height: 2px;
    position: absolute;
    top: calc(50% - 2px);
    width: 9px;
  }

  &::before {
    left: 0;
    transform: rotate(40deg);
  }

  &::after {
    right: 13px;
    transform: rotate(-40deg);
  }

  &:hover {
    .static-header__arrow,
    .static-header__phones-drop-down {
      display: block;
    }

    &::before,
    &::after {
      background: $color-orange;
    }
  }

  .static-header__arrow {
    left: 14px;
  }
}

.static-header__phone {
  align-items: center;
  color: $color-title;
  cursor: pointer;
  display: flex;
  font-size: 14px;
  font-weight: 700;
  letter-spacing: 1px;
  line-height: 21px;
  margin: 5px 10px;
  white-space: nowrap;
  width: 100%;

  @media (min-width: 960px) and (max-width: 1024px) {
    letter-spacing: 0;
    margin: 5px 0;
  }

  &:hover {
    color: $color-orange;
  }
}

.static-header__phone-flag {
  align-items: center;
  border-radius: 4px;
  display: flex;
  height: 20px;
  justify-content: center;
  margin: 0 7px 0 0;
  overflow: hidden;
  width: 26px;

  img {
    height: 20px;
    width: auto;
    max-width: none;
    border-radius: 4px;
    object-fit: cover;
  }
}

.static-header__phone-flag--usa {
  img {
    margin-left: 2px;
  }
}

.static-header__phones-drop-down {
  border-radius: 4px;
  box-shadow: $static-header-drop-down-box-shadow;
  display: none;
  overflow: hidden;
  position: absolute;
  right: -70px;
  text-align: left;
  top: 100%;
  width: 180px;
  z-index: 111;
}

.static-header__phones-wrapper {
  background: $color-white;
  padding: 10px 0;
}

.static-header__phones-link {
  align-items: center;
  color: $color-grey;
  display: flex;
  font-size: 15px;
  padding: 6px 15px;

  &:hover {
    background: $color-border;
    color: $color-blue;

    g,
    path {
      fill: $color-blue;
    }
  }

  svg {
    display: block;
    width: 20px;
    margin-right: 10px;
  }
}

.menu__apps {
  display: none;
  padding-top: 10px;
  padding-bottom: 10px;

  @media #{$tablet} {
    display: block;
  }

  &--item {
    width: 100%;
    margin-bottom: 15px;
    background: #000;
    border-radius: 4px;
    height: 42px;

    img {
      width: 100%;
      margin-bottom: 15px;
      height: 100%;
    }
  }

  &-wrapper {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
  }

  &-title {
    position: relative;
    width: 100%;
    color: #bec2cd;
    font-size: 14px;
    font-weight: 700;
    line-height: 2;
    letter-spacing: 0.35px;
    text-align: center;
    margin-bottom: 5px;
    text-transform: uppercase;

    &:after,
    &:before {
      position: absolute;
      top: 40%;
      width: calc(50% - 75px);
      height: 1px;
      content: "";
      background-color: #bec2cd;
    }

    &:before {
      left: 0;
    }

    &:after {
      right: 0;
    }
  }
}

// Search

.static-header__search {
  background: $static-header-search-bg;
  box-sizing: border-box;
  height: 89px;
  left: 0;
  padding: 25px;
  position: absolute;
  right: 0;
  top: -89px;
  transition: top ease-in-out 0.2s;
  z-index: 1;

  &.open-search {
    top: 0;
  }

  @media #{$tablet} {
    height: 76px;
    top: -76px;
    background: white;
    padding: 20px 25px 25px;
  }

  &-form {
    align-items: center;
    display: flex;
    width: 100%;
    position: relative;
  }

  &-submit {
    background: url("@/static/images/partials/header/icon-search.png") no-repeat 50% 50%;
    border: 0;
    cursor: pointer;
    height: 36px;
    width: 36px;

    @media #{$tablet} {
      background-color: $color-white;
      background-image: url("@/static/images/partials/header/icon-enter.svg");
      background-repeat: no-repeat;
      background-position: center center;
      position: absolute;
      right: 24px;
    }
  }

  &-input {
    background: transparent;
    border: 0;
    color: $color-orange;
    font-size: 17px;
    height: 36px;
    outline: none;
    padding: 0 5px;
    width: 100%;

    &::placeholder {
      color: $color-orange;
    }

    &:focus {
      &::placeholder {
        color: transparent;
      }
    }

    @media #{$tablet} {
      color: #303030;
      font-size: 14px;
      line-height: 20px;
      padding: 0 0 0 45px;

      &::placeholder {
        color: #303030;
      }
    }
  }

  &-close {
    background: transparent;
    border: 0;
    color: $color-white;
    cursor: pointer;
    font-size: 50px;
    height: 35px;
    line-height: 34px;
    text-align: center;
    transform: rotate(45deg);
    width: 36px;

    &:hover {
      color: darken($color-white, 10%);
    }

    @media #{$tablet} {
      color: $color-orange;
      position: absolute;
      left: -10px;
      top: -5px;
    }
  }
}

/* Chrome 28+, Now Also Safari 9+, Firefox, and Microsoft Edge */
@supports (-webkit-touch-callout: none) {
  .static-header__search-close {
    left: -40px;
    top: -13px;
  }
}

@media #{$tablet} {
  body .holdResults._siq_main_searchbox {
    &#siq_searchIndexResult {
      min-width: 100% !important;
      left: 0 !important;
      top: 66px !important;
      right: 0 !important;
      z-index: 9999 !important;
      box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);

      .topArrow {
        display: none !important;
      }

      ul {
        border: 0 !important;
        top: -2px;

        li.sectionHead {
          display: none !important;
        }

        li.siq-autocomplete {
          position: relative !important;

          &:after {
            content: "";
            left: 60px;
            right: 20px;
            top: -1px;
            position: absolute;
            height: 1px;
            background-color: $color-border;
          }
        }

        li > a {
          padding: 15px 20px !important;
          flex-direction: row !important;
          display: flex !important;
          align-items: center;

          .siq_resultLeft {
            width: 24px !important;
            height: 24px !important;
            min-width: 24px !important;
            margin-right: 15px !important;

            &.has-image {
              img {
                width: 24px !important;
                height: 24px !important;
              }
            }
          }
        }
      }
    }
  }
}

// Base

.static-header__arrow {
  bottom: 0;
  display: none;
  position: absolute;

  &::after {
    border-color: transparent transparent $color-white transparent;
    border-style: solid;
    border-width: 0 9px 9px 9px;
    bottom: -1px;
    content: "";
    height: 0;
    position: absolute;
    right: -2px;
    width: 0;
    z-index: 12;
  }
}

// Authorization buttons

.static-header__auth-button {
  align-items: center;
  display: flex;

  @media #{$tablet} {
    margin-right: 5px;
  }

  @media #{$mobile} {
    position: fixed;
    right: 10px;
    top: 25px;
    z-index: 1041;
  }

  &.user-logged {
    margin: 0;
  }

  .btn-link {
    color: $color-orange;
    font-weight: 700;
    margin-right: 15px;
  }

  .btn-auth {
    text-align: center;
    white-space: nowrap;

    @media #{$tablet} {
      display: none;
    }
  }

  .btn-orange {
    background: $color-orange;
    border: 1px solid $color-orange;
    border-radius: 0.3rem;
    box-sizing: border-box;
    color: $color-white;
    font-weight: 700;
  }

  .btn-link {
    border: 1px solid $color-orange;
  }

  .btn-my-account {
    margin-left: 10px;
    white-space: nowrap;

    @media #{$tablet} {
      display: none;
    }
  }
}

.btn-my-account--desktop {
  background: url("@/static/images/partials/header/icon-user-account.svg");
  display: none;
  height: 25px;
  margin-left: 10px;
  width: 25px;

  @media #{$tablet} {
    display: block;
    margin-right: 5px;
  }
}

.static-header__auth-button-tablet {
  display: none;
  position: relative;

  @media #{$tablet} {
    display: block;
    margin-right: 15px;
  }
}

.static-header__auth-icon {
  cursor: pointer;
  margin-left: 10px;
}

.static-header__auth-tooltip {
  padding-top: 14px;
  position: absolute;
  right: -20px;
  top: 100%;
  width: 122px;
}

.static-header__auth-tooltip-content {
  background: $color-white;
  border-radius: 4px;
}

.static-header__auth-tooltip-list {
  border-radius: 0 0 4px 4px;
  box-shadow: 0 5px 20px 0 rgba(0, 0, 0, 0.2);
  padding: 11px 0;
  position: absolute;
  background: $color-white;
  width: 100%;
}

.static-header__auth-tooltip-arrow {
  height: 9px;
  margin-left: 83px;
  position: relative;
  width: 18px;

  &::before {
    border-color: transparent transparent $color-orange transparent;
    border-style: solid;
    border-width: 0 9px 9px;
    content: "";
    height: 0;
    left: 0;
    position: absolute;
    top: 2px;
    width: 0;
  }

  &::after {
    border-color: transparent transparent $color-white transparent;
    border-style: solid;
    border-width: 0 9px 9px;
    content: "";
    height: 0;
    left: 0;
    position: absolute;
    top: 5px;
    width: 0;
  }
}

.static-header__auth-tooltip-line {
  background: $color-orange;
  border-radius: 4px 4px 0 0;
  height: 2px;
}

.static-header__auth-tooltip-item {
  align-items: center;
  color: $color-grey;
  display: flex;
  font-size: 15px;
  padding: 6px 15px;
  text-transform: uppercase;
  cursor: pointer;

  svg {
    margin: 0 10px 0 0;
  }

  &:hover {
    background: $color-border;
    color: $color-blue;

    svg {
      g,
      path {
        fill: $color-blue;
      }
    }
  }
}
