$footer-bg: #11467d;
$footer-color-copyright: rgba(255, 255, 255, .2);

.footer {
  background: $footer-bg;
  font-size: 14px;
  min-height: 490px;
  z-index: 1;
  position: relative;

  @media #{$tablet} {
    min-height: 510px;
  }

  @media #{$mobile} {
    min-height: auto;
    padding-bottom: 20px;
  }

  .container {
    max-width: 1400px;
  }

  &__top-block {
    align-items: flex-start;
    border-bottom: 1px solid $footer-color-copyright;
    display: flex;
    justify-content: space-between;
    padding: 60px 0 45px;

    @media #{$tablet} {
      padding: 30px 0;
      flex-direction: column-reverse;
      align-items: center;
    }

    @media #{$mobile} {
      padding: 10px 0;
    }
  }

  &__apps {
    margin-top: 50px;

    @media #{$tablet} {
      margin-top: 0;
      text-align: center;
    }

    &--wrapper {
      margin: 15px 0;
      display: flex;
      justify-content: space-between;

      @media #{$mobile} {
        width: 100%;
        flex-direction: column;
      }
    }

    &--item {
      @media #{$mobile} {
        width: 100%;
        background-color: $color-white;
        border-radius: 4px;

        img {
          width: 50%;
        }
      }

      &:first-of-type {
        margin-right: 15px;

        @media #{$mobile} {
          margin-right: 0;
          margin-bottom: 15px;
        }
      }
    }
  }

  &__column--info {
    max-width: 300px;
    width: 100%;

    @media #{$tablet} {
      margin-top: 30px;
    }

    @media #{$mobile} {
      max-width: unset;
    }
  }

  &__column--navigation {
    display: flex;
    justify-content: flex-end;

    @media #{$tablet} {
      width: 100%;
      justify-content: center;
    }

    @media #{$mobile} {
      display: none;
    }
  }

  &__column--tfx-navigation {
    .footer__navigation-column {
      width: 200px;
    }
  }

  &__logo {
    display: block;
    margin: 0 0 25px;

    @media #{$tablet} {
      display: none;
    }
  }

  &__logo-image {
    margin: 0;
    height: 35px;
    width: auto;
  }

  &__text {
    color: $color-white;
    font-size: 14px;
    letter-spacing: .2px;
    line-height: 1.29;

    @media #{$tablet} {
      display: none;
    }
  }

  &__navigation-column {
    margin: 0;
    width: 280px;

    &:last-child {
      margin: 0;
      width: 190px;
    }

    @media #{$tablet} {
      margin: 0 30px 0 0;
      width: calc(33% - 30px);
    }

    @media #{$mobile} {
      margin: 0 0 20px 0;
      width: 100%;
      text-align: center;
    }
  }

  &__navigation-title {
    color: $color-blue;
    font-size: 14px;
    font-weight: 700;
    letter-spacing: 1.4px;
    line-height: 1;
    margin: 0 0 15px;
    text-transform: uppercase;
    text-align: left;
  }

  &__navigation-list {
    list-style-type: none;
    margin: 0;
    padding: 0;

    li {
      margin: 0 0 15px;

      @media #{$mobile} {
        margin: 0 0 10px;
      }
    }
  }

  &__navigation-link {
    color: $color-white;
    display: inline-block;
    font-size: 14px;
    letter-spacing: .91px;
    line-height: 1.2;
    text-decoration: none;

    &:hover {
      color: darken($color-white, 20%);
    }
  }

  &__copyright {
    color: $footer-color-copyright;
  }

  &__info {
    color: $color-white;
    display: flex;
    font-size: 40px;
    letter-spacing: -.5px;
    line-height: 1;

    @media #{$md} {
      font-size: 30px;
    }

    @media #{$tablet} {
      font-size: 20px;
    }

    @media #{$mobile} {
      display: none;
    }
  }

  &__info-item {
    margin-left: 65px;

    @media #{$lg} {
      margin-left: 50px;
    }

    @media #{$md} {
      margin-left: 30px;
    }
  }

  &__bottom-block {
    align-items: center;
    display: flex;
    height: 110px;
    justify-content: space-between;

    @media #{$tablet} {
      padding: 10px 0 0;
    }

    @media #{$mobile} {
      flex-direction: column;
      height: auto;
    }
  }
}
