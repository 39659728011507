$font-size-default: 16px;
$enable-smooth-scroll: true !default;
$font-weight-base : normal;
$line-height-base: 1.2;
$paragraph-margin-bottom:   1rem !default;
$body-text-align:           null !default;
$small-font-size:             .875em !default;
$sub-sup-font-size:           .75em !default;

// scss-docs-start table-variables
$table-cell-padding-y:        .5rem !default;
$table-cell-padding-x:        .5rem !default;
$table-cell-padding-y-sm:     .25rem !default;
$table-cell-padding-x-sm:     .25rem !default;

$table-cell-vertical-align:   top !default;

// scss-docs-start spacer-variables-maps
$spacer: 1rem !default;
$spacers: (
        0: 0,
        1: calc($spacer / 4),
        2: calc($spacer / 2),
        3: $spacer,
        4: calc($spacer * 1.5),
        5: calc($spacer * 3),
) !default;

// scss-docs-start headings-variables
$headings-margin-bottom:      calc($spacer / 2) !default;
$headings-font-family:        null !default;
$headings-font-style:         null !default;
$headings-font-weight:        500 !default;
$headings-line-height:        1.2 !default;
$headings-color:              null !default;


// Base
$white: #fff;
$color-main: #1e2a42;
$color-text-dark: #1e2a42;
$color-text: #202b43;
$color-blue: #2586ea;
$color-link: #2586ea;
$color-date: #b8b8b8;
$color-title: #11467d;
$color-blue-dark: #1A6DBD;
$color-sky-blue: #E9F3FD;
$color-blue-navi: #15487B;

// Colors
$color-white: #fff;
$color-black: #000;
$color-orange: #f8620e;
$color-dark-orange: #aa440b;
$color-light-black: #404040;
$color-grey: #656b6f;
$color-light-grey: #888;
$color-lightest-grey: #f2f2f2;
$color-lighter-grey: #e5e5e5;
$color-grey-text-list: #aaa;
$color-darkest: #303030;

// Buttons
$bg-button-primary: #2586ea;
$border-button-white: rgba(255, 255, 255, .2);
$border-button-blue: rgba(37, 134, 234, .2);
$bg-btn-dark-green: #0f940c;
$bg-dtn-online-chat: #f96b1b;

// Navigation
$color-link-navigation: #1e2a42;

// Border
$color-border: #d4d4d4;

// Border Radius
$border-radius-base: 4px;

// Breadcrumbs
$color-breadcrumbs: #A6C5E6;

// Side Bar
$sidebar-gb: #e3e3e3;


// FAQ
$faq-item-color-border: rgba(184, 184, 184, .6);
$faq-collapsed-bg: rgba(36, 133, 234, .05);

// Indents
$indent-left-right-side-page: 30px;
$indent-left-right-side-page-mobile: 10px;

// DEFAULT
$color-bg-loader: rgba(255, 255, 255, .75);
$color-blue-bg: #2588ED;

// Box shadow
$box-shadow-image: 3px 8px 17px rgba(9, 19, 35, .24);

// Media Queries

$xl: '(max-width: 1600px)';
$lg: '(max-width: 1400px)';
$lg-xl: '(max-width: 1300px)';
$lg-md: '(max-width: 1250px)';
$md: '(max-width: 1200px)';
$tablet: '(max-width: 1023px)';
$sm: '(max-width: 1000px)';
$mobile: '(max-width: 768px)';
$extra-small: '(max-width: 480px)';
$min-width-md: '(min-width: 1025px)';
$min-width-xl: '(min-width: 1600px)';

$ie: 'screen\0';
